import { querifyData } from '~/utils/querifyData';

export const BASE_API = 'api';
export const API_VERSION_V1 = 'v1';
export const API_VERSION_V2 = 'v2';
/**
 * Console Paths
 */
export const BASE_PATHS = {
  ADD: 'add',
  ACTIVITY_LOGS: 'activityLogs',
  BILLING: 'billing',
  CONNECTORS: 'connectors',
  DASHBOARD: 'dashboard',
  DUPLICATE_LINK: 'duplicate',
  SYNCS: 'syncs',
  EDIT_LINK: 'edit',
  EMBED: 'embed',
  FLOW_BUILDER: 'flow-builder',
  GUIDE: 'guide',
  ITEMS: 'items',
  LINKS: 'links',
  LOGIN: 'login',
  ONE_CLICK_EXPORT: 'one-click-export',
  ORGANIZATIONS: 'organizations',
  OVERVIEW: 'overview',
  PEOPLE: 'people',
  PROFILE: 'profile',
  RESET: 'reset',
  SIGNUP: 'signup',
  SSO: 'sso',
  STATUS: 'status',
  STYLEGUIDE: 'styleguide',
  WELCOME: 'welcome',
  PROJECT_SYNC: 'project-sync',
  TASK_SYNC: 'task-sync',
  WORKFLOWS: 'workflows',
};

export const METHODS = {
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  POST: 'POST',
  DELETE: 'DELETE',
};

export const ABSOLUTE_PATHS = {
  ADD_LINK: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.LINKS}/${BASE_PATHS.ADD}`,
  ADD_MULTISYNC: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.MULTISYNCS}/${BASE_PATHS.ADD}`,
  CREATE_ONE_CLICK_EXPORT: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.ONE_CLICK_EXPORT}`,
  BILLING: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.ORGANIZATIONS}`,
  DASHBOARD: `/${BASE_PATHS.DASHBOARD}`,
  DUPLICATE_LINK: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.LINKS}/${BASE_PATHS.DUPLICATE_LINK}`,
  EDIT_FLOW_BUILDER: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.FLOW_BUILDER}/${BASE_PATHS.EDIT_LINK}`,
  EDIT_LINK: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.LINKS}/${BASE_PATHS.EDIT_LINK}`,
  EDIT_MULTISYNC: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.MULTISYNCS}/${BASE_PATHS.EDIT_LINK}`,
  LOGIN: `/${BASE_PATHS.LOGIN}`,
  ORGANIZATIONS: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.ORGANIZATIONS}`,
  PROFILE: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.PROFILE}`,
  PROJECT_SYNC_PRICING: (orgId) => `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.ORGANIZATIONS}/${orgId}/pricing/project-sync`,
  RESET: `/${BASE_PATHS.RESET}`,
  SIGNUP: `/${BASE_PATHS.SIGNUP}`,
  SSO: `/${BASE_PATHS.SSO}`,
  SYNCS: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.SYNCS}`,
  PROJECT_SYNC: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.SYNCS}/${BASE_PATHS.PROJECT_SYNC}`,
  TASK_SYNC: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.SYNCS}/${BASE_PATHS.TASK_SYNC}`,
  WELCOME: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.WELCOME}`,
  WORKFLOWS: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.WORKFLOWS}`,
  EDIT_WORKFLOW: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.WORKFLOWS}/${BASE_PATHS.EDIT_LINK}`,
  ACTIVITY_LOGS: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.ACTIVITY_LOGS}`,
  FLOW_BUILDER_ADD: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.FLOW_BUILDER}/${BASE_PATHS.ADD}`,
  FLOW_BUILDER_EDIT: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.FLOW_BUILDER}/${BASE_PATHS.EDIT_LINK}`,
  FLOW_BUILDER_WORKFLOW: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.FLOW_BUILDER}/workflow`,
  FLOW_BUILDER_DUPLICATE: `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.FLOW_BUILDER}/${BASE_PATHS.DUPLICATE_LINK}`,
  USAGE_BILLING_OVERVIEW: (orgId) =>
    `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.ORGANIZATIONS}/${orgId}/${BASE_PATHS.BILLING}/${BASE_PATHS.PROJECT_SYNC}/${BASE_PATHS.OVERVIEW}`,
  MEMBERS: (orgId) => `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.ORGANIZATIONS}/${orgId}/people/members`,
  ITEM_STATUS_PAGE: (taskId) => `/${BASE_PATHS.DASHBOARD}/${BASE_PATHS.ITEMS}/${taskId}/${BASE_PATHS.STATUS}`,
};

export const FLOW_BUILDER_PAGES = {
  GUIDE: 'guide',
  TOOL_SELECTION: 'tool-selection',
  FLOW_DIRECTION: 'flow-direction',
  RULES: 'rules',
  MAPPINGS: 'mappings',
};

export const ONE_CLICK_EXPORT_PAGES = {
  START_EXPORT: 'start-export',
  CONNECT_TOOLS: 'connect-tools',
  ADD_ON_SETUP: 'add-on-setup',
  SETUP_COMPLETED: 'setup-completed',
};

export const HELP_PATHS = {
  UNITO_HELP_URL: 'https://guide.unito.io/',
  CREATE_SYNC_ACCOUNT_URL: 'https://guide.unito.io/how-to-create-a-bot-user-in-unito',
  UNITO_GUIDE_TROUBLESHOOTING: 'https://guide.unito.io/how-to-fix-common-unito-issues',
  UNITO_GUIDE_BEST_PRACTICES: 'https://unito.io/blog/category/use-cases/',
  PRICING_PAGE_URL: 'https://unito.io/pricing/',
  CANT_FIND_REPO: 'https://guide.unito.io/how-to-fix-common-unito-issues',
  TRELLO_POWER_UP_GUIDE: 'https://info.trello.com/power-ups/unito',
  REVERT_MISTAKES_GUIDE: 'https://guide.unito.io/how-to-use-rules-to-undo-mistakes-in-trello',
  FILTER_TASKS: 'https://guide.unito.io/how-to-set-up-rules',
  WHAT_ARE_USERS: 'https://guide.unito.io/how-does-unitos-pricing-work',
  WHAT_ARE_MULTISYNCS: 'https://guide.unito.io/',
  GRANDFATHERING: 'https://guide.unito.io/how-are-flows-affected-when-i-change-unito-plans',
};

export const IMAGE_PATHS = {
  UNITO_LOGO_COLOR: 'https://unito-app-public-static.s3.us-east-1.amazonaws.com/unito-logomark.svg',
};

const getCustomFieldsRoute = (providerIdentityId, containerId, itemType, getUncached) =>
  `v1/fields/custom?providerIdentityId=${encodeURIComponent(providerIdentityId)}&containerId=${encodeURIComponent(
    containerId,
  )}&itemType=${encodeURIComponent(itemType)}&getUncached=${getUncached}`;

const getFieldValuesRoute = ({
  providerIdentityId,
  containerId,
  containerType,
  fieldId,
  kind,
  category = '',
  searchString = '',
  fetchDefault,
  filters,
  itemType,
}) =>
  `v1/fieldValues?providerIdentityId=${encodeURIComponent(providerIdentityId)}&containerId=${encodeURIComponent(
    containerId,
  )}&containerType=${encodeURIComponent(containerType)}&fieldId=${encodeURIComponent(
    fieldId,
  )}&kind=${encodeURIComponent(kind)}&category=${encodeURIComponent(category)}${
    searchString ? `&search=${encodeURIComponent(searchString)}` : ''
  }${fetchDefault ? `&fetchDefault=${encodeURIComponent(fetchDefault)}` : ''}&filters=${encodeURIComponent(
    JSON.stringify(filters) || '',
  )}&itemType=${encodeURIComponent(itemType)}`;
const getFieldValueRoute = (providerIdentityId, containerId, fieldId, kind, fieldValueId, itemType) =>
  `v1/fieldValues/${encodeURIComponent(fieldValueId)}?providerIdentityId=${encodeURIComponent(
    providerIdentityId,
  )}&containerId=${encodeURIComponent(containerId)}&fieldId=${encodeURIComponent(fieldId)}&kind=${encodeURIComponent(
    kind,
  )}&itemType=${encodeURIComponent(itemType)}`; // eslint-disable-line

const getSubscriptionRoute = (subscriptionId) => `v1/billing/subscriptions/${encodeURIComponent(subscriptionId)}`;
const getOrganizationInvoicesRoute = (organizationId) =>
  `v1/billing/organizations/${encodeURIComponent(organizationId)}/invoices`;
const getBillingOrganizationRoute = (organizationId) => `v1/billing/organizations/${organizationId}`;
const getOrganizationRoute = (organizationId) => `v1/organizations/${organizationId}`;
const getCollaboratorsRoute = (organizationId) => `v1/organizations/${organizationId}/collaborators`;
const getOrganizationMembersRoute = (organizationId) => `v1/organizations/${organizationId}/members`;
const getOrganizationCoworkersRoute = (organizationId) => `v1/organizations/${organizationId}/coworkers`;
const getOrganizationInvites = (organizationId) => `v1/invites?organizationId=${encodeURIComponent(organizationId)}`;
const getUserPendingInvites = () => `v1/invites?state=pending`;
const getLinkUsersRoute = (linkId, side) => `v1/links/${linkId}/users?side=${side}`;

const getSearchLinksRoute = ({ siteAdminSearchString, page = 0, pageSize = 0, searchString = '', kinds = [] }) => {
  const kindsParam = kinds.length ? `&kinds=${encodeURIComponent(kinds.join(','))}` : '';
  const searchStringParam = searchString ? `&searchString=${encodeURIComponent(searchString)}` : '';
  return `v1/links?search=${encodeURIComponent(
    siteAdminSearchString,
  )}&page=${page}&pageSize=${pageSize}${searchStringParam}${kindsParam}`;
};
const getSearchWorkflowsRoute = (siteAdminSearchString) =>
  `v1/workflows?search=${encodeURIComponent(siteAdminSearchString)}`;

export const getEditFlowBuilderRoute = (linkId, queryParams = '') =>
  `${ABSOLUTE_PATHS.EDIT_FLOW_BUILDER}/${encodeURIComponent(linkId)}/${BASE_PATHS.GUIDE}${queryParams}`;

export const getDuplicateFlowBuilderRoute = (linkId, section = '') =>
  `${ABSOLUTE_PATHS.FLOW_BUILDER_DUPLICATE}/${encodeURIComponent(linkId)}${section && `/${section}`}`;

/**
 * API Paths
 */
export const API_PATHS = {
  AUTOMAP_FIELD_VALUES: 'v1/fields/automap',
  AUTOMAP_USERS: (linkId) => `v1/links/${encodeURIComponent(linkId)}/automap_users`,
  CANCEL_SUBSCRIPTION: getSubscriptionRoute,
  CREATE_CONTAINER: 'v1/containers',
  SEND_HUBSPOT_INVITE: 'v1/hubspot/invite',
  SEND_HUBSPOT_REFERRAL: 'v1/hubspot/referral',
  CREATE_SUBSCRIPTION: 'v1/billing/subscriptions',
  DELETE_LINK: (linkId) => `v1/links/${encodeURIComponent(linkId)}`,
  DIAGNOSE_LINK: (linkId) => `v1/links/${encodeURIComponent(linkId)}/diagnose`,
  DISCONNECT_PROVIDER_IDENTITY: (providerIdentityId) =>
    `v1/providerIdentities/${encodeURIComponent(providerIdentityId)}`,
  CONNECT_PROVIDER_IDENTITY: (providerIdentityId) =>
    `v1/providerIdentities/${encodeURIComponent(providerIdentityId)}/connect_to`,
  GENERATE_FIELD_ASSOCIATIONS: (linkId) => `v1/fields/generate_associations?linkId=${linkId}`,
  GENERATE_VALUE_MAPPING_ON_FIELD_ASSOCIATIONS: (linkId) =>
    `v1/links/${encodeURIComponent(linkId)}/fields/generate_mapping_associations`,
  GET_CAPABILITIES_FIELD_TYPES: 'v1/fields/capabilities_field_types',
  DUPLICATE_SYNC: (linkId, containerIds, providerIdentityIds, containerTypes, itemTypes) =>
    `v1/links/${encodeURIComponent(linkId)}/replication?containerIdA=${encodeURIComponent(
      containerIds.A,
    )}&containerIdB=${encodeURIComponent(containerIds.B)}&providerIdentityIdA=${encodeURIComponent(
      providerIdentityIds.A,
    )}&providerIdentityIdB=${encodeURIComponent(providerIdentityIds.B)}&containerTypeA=${encodeURIComponent(
      containerTypes.A,
    )}&containerTypeB=${encodeURIComponent(containerTypes.B)}&itemTypeA=${encodeURIComponent(
      itemTypes.A,
    )}&itemTypeB=${encodeURIComponent(itemTypes.B)}`,
  GET_ACTIVITY_LOGS: (organizationId, page, pageSize, filters, sort, since) =>
    `v1/activityLogs/${encodeURIComponent(organizationId)}?${querifyData(
      Object.entries({ page, pageSize, filters, sort, since }),
    )}`,
  GET_ALLOWED_PROVIDER_IDENTITIES: (providerIdentityId, containerId, containerType, itemType) =>
    `v1/containers/${encodeURIComponent(containerId)}/providerIdentityIds?providerIdentityId=${encodeURIComponent(
      providerIdentityId,
    )}&containerType=${encodeURIComponent(containerType)}&itemType=${encodeURIComponent(itemType)}`,
  GET_APP_CONFIG: 'v1/config',
  GET_PROVIDER_IDENTITY_INSTALLATIONS: (providerName) => `v1/providerIdentities/installations/${providerName}`,
  GET_COLLABORATORS: getCollaboratorsRoute,
  PATCH_COLLABORATOR: getCollaboratorsRoute,
  GET_CONTAINERS: (organizationId, providerIdentityId, searchValue, containerType, itemType) => {
    const path = `v1/containers?organizationId=${organizationId}&providerIdentityId=${encodeURIComponent(
      providerIdentityId,
    )}&containerType=${encodeURIComponent(containerType)}&itemType=${encodeURIComponent(itemType)}`;
    if (!searchValue) {
      return path;
    }
    return path.concat(`&search=${encodeURIComponent(searchValue)}`);
  },
  GET_CONTAINER: (providerIdentityId, containerId, containerType, itemType, linkId, getUncached) =>
    `v1/containers/${encodeURIComponent(
      containerId,
    )}?providerIdentityId=${providerIdentityId}&containerType=${containerType}&itemType=${itemType}${
      linkId ? `&linkId=${linkId}` : ''
    }&getUncached=${getUncached}`,
  GET_CONTAINER_PLUGINS: (providerIdentityId, containerId) =>
    `v1/containers/${encodeURIComponent(containerId)}/plugins?providerIdentityId=${providerIdentityId}`,
  GET_ACTIVE_CONTAINERS: (organizationId) => `v1/links/containers?organizationId=${organizationId}`,
  GET_ORGANIZATION_INVOICES: getOrganizationInvoicesRoute,
  GET_CUSTOM_FIELDS: getCustomFieldsRoute,
  GET_FLAGS: (organizationId) => `v1/flags?organizationId=${organizationId}`,
  GET_FIELD_VALUES: getFieldValuesRoute,
  GET_FIELD_VALUE: getFieldValueRoute,
  GET_LINK: (linkId) => `v1/links/${encodeURIComponent(linkId)}`,
  GET_LINKS: ({
    organizationId = '',
    containerId = '',
    page = 0,
    pageSize = 0,
    searchString = '',
    provider = [],
    state = [],
    kinds = [],
  }) => {
    const searchStringParam = searchString ? `&searchString=${encodeURIComponent(searchString)}` : '';
    const providerParam = provider.length ? `&provider=${encodeURIComponent(provider.join(','))}` : '';
    const stateParam = state.length ? `&state=${encodeURIComponent(state.join(','))}` : '';
    const kindsParam = kinds.length ? `&kinds=${encodeURIComponent(kinds.join(','))}` : '';
    return `v1/links?organizationId=${encodeURIComponent(organizationId)}&containerId=${encodeURIComponent(
      containerId,
    )}&page=${page}&pageSize=${pageSize}${searchStringParam}${providerParam}${stateParam}${kindsParam}`;
  },
  GET_LINK_USERS: getLinkUsersRoute,
  GET_ORGANIZATIONS: 'v1/organizations',
  GET_ORGANIZATION_BY_ID: (organizationId) => `v1/organizations/${encodeURIComponent(organizationId)}`,
  GET_ORGANIZATION_COWORKERS: getOrganizationCoworkersRoute,
  GET_ORGANIZATION_INVITES: getOrganizationInvites,
  GET_INVITE_WORKSPACE_NAME: 'v1/invites/workspaceName',
  GET_ORGANIZATION_MEMBERS: getOrganizationMembersRoute,
  GET_ORGANIZATION_METRICS: (organizationId = '') => `v1/organizations/${encodeURIComponent(organizationId)}/metrics`,
  GET_ORGANIZATION_REPORT_URL: (organizationId, reportId) =>
    `v1/organizations/${encodeURIComponent(organizationId)}/report/${encodeURIComponent(reportId)}`,
  GET_ORGANIZATION_UNITO_IDENTITIES: (organizationId = '') =>
    `v1/organizations/${encodeURIComponent(organizationId)}/unitoIdentities`,
  GET_ORGANIZATION_WORKFLOWS: (organizationId) => `v1/organizations/${encodeURIComponent(organizationId)}/workflows`,
  CREATE_WORKFLOW: (organizationId) => `v1/organizations/${encodeURIComponent(organizationId)}/workflows`,
  GET_WORKFLOW: (workflowId) => `v1/workflows/${encodeURIComponent(workflowId)}`,
  PATCH_WORKFLOW: (workflowId) => `v1/workflows/${encodeURIComponent(workflowId)}`,
  GET_LINKS_BY_WORKFLOW_ID: (workflowId) => `v1/workflows/${encodeURIComponent(workflowId)}/links`,
  POST_GENERATE_WORKFLOW_RESOURCES_ON_DELETE: (organizationId) =>
    `v1/organizations/${encodeURIComponent(organizationId)}/workflows/generate_delete`,
  UNMERGE_UNITO_IDENTITIES: (organizationId = '', unitoIdentityId = '') =>
    `v1/organizations/${encodeURIComponent(organizationId)}/unitoIdentities/${encodeURIComponent(
      unitoIdentityId,
    )}/unmerge`,
  MERGE_UNITO_IDENTITIES: (organizationId = '') =>
    `v1/organizations/${encodeURIComponent(organizationId)}/unitoIdentities/merge`,
  UPDATE_UNITO_IDENTITIES: (organizationId = '', unitoIdentityId = '') =>
    `v1/organizations/${encodeURIComponent(organizationId)}/unitoIdentities/${encodeURIComponent(unitoIdentityId)}`,
  GET_ORGANIZATION_USAGE: (organizationId = '') => `v1/organizations/${encodeURIComponent(organizationId)}/usage`,
  GET_ORGANIZATION_ITEMS_USAGE: (organizationId = '') =>
    `v1/organizations/${encodeURIComponent(organizationId)}/items-usage`,
  GET_ORGANIZATION_SYNC_ACTIVITY: (organizationId = '') =>
    `v1/organizations/${encodeURIComponent(organizationId)}/syncActivity`,
  GET_TASK_SYNC_TASK_COUNT: 'v1/links/taskSync/count',
  GET_PLANS: (organizationId = '') => `v1/billing/plans?organizationId=${encodeURIComponent(organizationId)}`,
  GET_ORGANIZATION_PLAN_PROFILE: (organizationId = '') =>
    `v1/organizations/${encodeURIComponent(organizationId)}/currentPlan`,
  GET_PLAN_REJECTION_REASONS: (orgId) =>
    `v1/billing/plans/generate_rejection_reasons?organizationId=${encodeURIComponent(orgId)}`,
  GET_USER_PENDING_INVITES: getUserPendingInvites,
  DELETE_WORKFLOW: (workflowId) => `v1/workflows/${encodeURIComponent(workflowId)}`,
  GET_WORKSPACES: (providerIdentityId) => `v1/workspaces?providerIdentityId=${encodeURIComponent(providerIdentityId)}`,
  IDENTIFY: (organizationId) => `v1/organizations/${encodeURIComponent(organizationId)}/refresh_segment`,
  LINKS: 'v1/links',
  LOGOUT: (shouldRevokeSession = false) => `v1/logout${shouldRevokeSession ? '?shouldRevokeSession=true' : ''}`,
  PROVIDERS: () => `v1/providers`,
  PROVIDERS_V2: (organizationId) => `v2/organizations/${organizationId}/providers`,
  PROVIDER_CONNECT_URL: (providerName, organizationId, linkId) =>
    `v1/providers/${providerName}/connectUrl?organizationId=${organizationId}&linkId=${linkId}`,
  PROVIDER_CAPABILITIES: (providerName, providerIdentityId) =>
    `v1/providers/${providerName}/capabilities?providerIdentityId=${providerIdentityId}`,
  PROVIDER_CAPABILITIES_FOR_ITEM: (providerName, providerIdentityId, containerId, itemType, linkId) =>
    `v1/providers/${providerName}/capabilitiesForItem?providerIdentityId=${providerIdentityId}&containerId=${encodeURIComponent(
      containerId,
    )}&itemType=${encodeURIComponent(itemType)}${linkId ? `&linkId=${encodeURIComponent(linkId)}` : ''}`,
  PROVIDER_IDENTITIES: 'v1/providerIdentities',
  PROVIDER_IDENTITY_VALIDATED: (providerIdentityId) => `v1/providerIdentities/${providerIdentityId}/validated`,
  REHYDRATE: 'auth/rehydrate',
  EXPOSE_TOKEN: 'auth/expose_token',
  RESET_PASSWORD: 'auth/reset',
  RESET_PASSWORD_CHANGE: (validationId) => `auth/reset/${encodeURIComponent(validationId)}`,
  RESEND_CONFIRMATION: 'auth/confirm',
  SAVE_LINK: (linkId) => `v1/links/${encodeURIComponent(linkId)}`,
  SAVE_LINK_V2: (linkId) => `v2/links/${encodeURIComponent(linkId)}`,
  SEARCH_LINKS: getSearchLinksRoute,
  SEARCH_WORKFLOWS: getSearchWorkflowsRoute,
  SYNC_LINK: (linkId) => `v1/links/${encodeURIComponent(linkId)}/sync`,
  AUTHENTICATE: 'v1/authenticate',
  TEST_CONNECTION: (providerName) => `v1/authenticate/${providerName}/test_connection`,
  VALIDATE_DOMAIN: (providerName) => `v1/authenticate/${providerName}/validate_domain`,
  UPDATE_INVITE: (inviteId) => `v1/invites/${encodeURIComponent(inviteId)}`,
  UPDATE_ORGANIZATION: getOrganizationRoute,
  GET_BILLING_ORGANIZATION: getBillingOrganizationRoute,
  UPDATE_BILLING_ORGANIZATION: getBillingOrganizationRoute,
  UPDATE_SUBSCRIPTION: getSubscriptionRoute,
  UPDATE_MEMBER_ROLE: (organizationId, memberId) => `v1/organizations/${organizationId}/members/${memberId}`,
  GET_ORGANIZATION_ROLES: (organizationId) => `v1/organizations/${organizationId}/roles`,
  REMOVE_ORGANIZATION_MEMBER: (organizationId, memberId, dryRun = true) =>
    `v1/organizations/${organizationId}/members/${memberId}?dryRun=${dryRun}`,
  RUN_SCRIPT: (scriptId) => `v1/scripts/${encodeURIComponent(scriptId)}`,
  GET_SCRIPTS: 'v1/scripts',
  CREATE_PROVIDER_CONTAINER: `v1/providerContainers`,
  ADD_BLOCK: (workflowId) => `v1/workflows/${encodeURIComponent(workflowId)}/blocks`,
  GET_PROVIDER_CONTAINERS_BY_WORKFLOW: (workflowId) =>
    `v1/workflows/${encodeURIComponent(workflowId)}/providerContainers`,
  DELETE_BLOCK: (workflowId, blockId) =>
    `v1/workflows/${encodeURIComponent(workflowId)}/blocks/${encodeURIComponent(blockId)}`,
  REMOVE_FLOW: (workflowId, linkId) =>
    `v1/workflows/${encodeURIComponent(workflowId)}/flow/${encodeURIComponent(linkId)}`,
  DRAFT: 'v1/drafts',
  UPDATE_DRAFT: (linkId) => `v1/drafts/${encodeURIComponent(linkId)}`, // used for get, put, patch, etc...
  GET_ITEM_INFO: (itemId) => `v1/items/${encodeURIComponent(itemId)}/status`,
  ONE_CLICK: `v1/links/oneclick`,
  ONE_CLICK_ACTIVATE: `v1/links/oneclick/activate`,
  DELETE_WORKSPACE: (orgId) => getOrganizationRoute(orgId),
  SYNC_LINK_ITEM: (itemId) => `v1/links/items/${encodeURIComponent(itemId)}/sync`,
  VALIDATE_LINK_CAN_BE_AUTOPOPULATED: (linkId) => `v1/links/${linkId}/can_autopopulate`,
  AUTOPOPULATE_FIELDS: (linkId) => `v1/links/${linkId}/fields/auto_populate_fields`,
};
